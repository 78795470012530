import React, { useState } from "react";

// import Header from "./components/header/Header";
import HallOfFame from "./pages/hallOfFame/HallOfFame";
import GiphyShuffle from "./components/giphy/giphyShuffle";

import styles from "./App.module.css";
import { Route, Routes } from "react-router";
import Lottery from "./pages/lottery/Lottery";
import Home from "./pages/Home/Home1";
import Constitution from "./pages/Constitution/Constitution1";
import Header from "./components/header/Header";

function App() {
  const [webPageLoading, setWebPageLoading] = useState(true);

  setTimeout(() => setWebPageLoading(false), 3000);

  //Dont forget to add a gay mode
  return (
    <div className={styles.app}>
      {webPageLoading ? (
        <GiphyShuffle />
      ) : (
        <>
          {/* <Header /> */}
          <Header />
          <Home />
          <Constitution />
          <HallOfFame />
        </>
      )}
    </div>
  );
}

export default App;
