import React from "react";
import styles from "./Home.module.css";
import MainCard from "../../containers/MainCard";

function Home() {
  return (
    <MainCard>
      <div id="home" className={styles.home}>
        <h2
          className={styles.homeHeader}
          data-aos-duration="750"
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-delay="0"
          data-aos-offset="100"
          // data-aos-mirror="true"
        >
          <b>JAGS OF AURORA FANTASY FOOTBALL LEAGUE</b>
        </h2>

        <section
          className={styles.newsTable}
          data-aos-duration="1000"
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-delay="0"
          data-aos-offset="100"
          data-aos-mirror="true"
        >
          <h3>2024 JOAFFL News</h3>
          <ul>
            <li>
              <b>6/26/2024 | </b>JOAFFL Draft set for September 3rd 2024.
            </li>
            <li>
              <b>2/19/2024 | </b>Misha annouces Adam as 2024 JOAFFL Commisioner.
            </li>
            <li>
              <b>1/7/2024 | </b>Miller Hands Trophy over to Cam. Congrats Cam Schnell on winning the 2023 JOAFFL season.
            </li>
            <li>
              <b>6/13/2023 | </b>Cam Schnell, Austin Guthlein and Jack Updyke
              have been elected as the 2023 JOAFFL Board members. - Misha
              Shatokhin
            </li>
            <li>
              <b>6/13/2023 | </b>"Not to mention, Miller of all people handing
              over the trophy to me 😂😂😂😂😂😂" - guess
            </li>
            <li>
              <b>6/13/2023 | </b>"I’m winning this year man. Might as well get
              used to it now. The original founder, that solid and round
              numbered year 10, revenge after how awful I was treated last
              season; and taking back the league from asterisk to asswhooping."
              - Cam Schnell
            </li>
            <li>
              <b>6/13/2023 | </b>yeet - Misha Shatokhin
            </li>
          </ul>
        </section>
      </div>
    </MainCard>
  );
}
export default Home;
