//External
import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";
//ReactIcons
//Internal

//Images
//CSS
import styles from ".//Header.module.css";

function Header() {
  //When the user scrolls the progress bar grows horizontally
  const updateBar = () => {
    const body = document.body;
    const bar = document.querySelector(`.${styles.bar}`);
    let scrollPos =
      (window.scrollY / (body.scrollHeight - window.innerHeight)) * 100;
    if (bar) {
      bar.style.width = `${scrollPos}%`;
    }
    requestAnimationFrame(updateBar);
  };
  updateBar();

  const navigate = useNavigate();

  return (
    <nav id="navbar" className={styles.header}>
      <div className={styles.progress}>
        <span className={styles.bar}></span>
      </div>
      <div className={styles.headerContainer}>
        <ul className={styles.headerNav}>
          <Link to="home" spy={true} smooth={true} offset={-100} duration={600}>
            <motion.div
              className={styles.navItem}
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 0.9 }}
              transition={{ type: "spring", stiffness: 400, damping: 17 }}
            >
              HOME
            </motion.div>
          </Link>
          <Link
            to="constitution"
            spy={true}
            smooth={true}
            offset={-60}
            duration={600}
          >
            <motion.div
              className={styles.navItem}
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 0.9 }}
              transition={{ type: "spring", stiffness: 400, damping: 17 }}
            >
              TUTION
            </motion.div>
          </Link>
          <Link
            to="hallOfFame"
            spy={true}
            smooth={true}
            offset={-60}
            duration={600}
          >
            <motion.div
              className={styles.navItem}
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 0.9 }}
              transition={{ type: "spring", stiffness: 400, damping: 17 }}
            >
              HOF
            </motion.div>
          </Link>
          <motion.div
            className={styles.navItem}
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: "spring", stiffness: 400, damping: 17 }}
            onClick={() => navigate("/losers")}
          >
            LOSERS
          </motion.div>


          {/* <motion.div
            className={styles.navItem}
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: "spring", stiffness: 400, damping: 17 }}
            onClick={() => navigate("/lottery")}
          >
            LOTTERY
          </motion.div> */}
        </ul>
      </div>
    </nav>
  );
}

export default Header;