import React from "react";
import styles from "./HallOfFame.module.css";
import MainCard from "../../containers/MainCard";

function HallOfFame() {
  return (
    <MainCard>
      <div id="hallOfFame" className={styles.hallfOfFame}>
        <h1
          className={styles.hofHeader}
          data-aos-duration="750"
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-delay="0"
          data-aos-offset="100"
        >
          <b>JOAFFL HALL OF FAME</b>
        </h1>

        <section
          className={styles.hofTable}
          data-aos-duration="1000"
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-delay="0"
          data-aos-offset="100"
          data-aos-mirror="true"
        >
          <ul>
            <h3>
              <b>X. 2023</b>
            </h3>
            <h4>Cam Schnell over Jack Updyke</h4>
            <h3>
              <b>IX. 2022* </b>
            </h3>
            <h4>Brandon Miller over Sam Nowak</h4>
            <h3>
              <b>VIII. 2021 </b>{" "}
            </h3>
            <h4>Jack Updyke over Austin Guthlein</h4>
            <h3>
              <b>VII. 2020 </b>{" "}
            </h3>
            <h4>Sam Nowak over Misha Shatokhin</h4>
            <h3>
              <b>VI. 2019 </b>{" "}
            </h3>
            <h4>Drew Daff over Brandon Miller</h4>
            <h3>
              <b>V. 2018 </b>{" "}
            </h3>
            <h4>Jack Updyke over Adam Dolata</h4>
            <h3>
              <b>IV. 2017 </b>{" "}
            </h3>
            <h4>Cam Schnell over Jack Updyke</h4>
            <h3>
              <b>III. 2016 </b>{" "}
            </h3>
            <h4>Sam Nowak over Drew Daff</h4>
            <h3>
              <b>II. 2015 </b>{" "}
            </h3>
            <h4>Jack Updyke over Cam Schnell</h4>
            <h3>
              <b>I. 2014 </b>{" "}
            </h3>
            <h4>Alex Avalon over Alex Hickey</h4>
          </ul>
        </section>
      </div>
    </MainCard>
  );
}
export default HallOfFame;
