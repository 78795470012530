import React from "react";
import styles from "./Constitution.module.css";
import MainCard from "../../containers/MainCard";

function Constitution() {
  return (
    <MainCard>
      <div id="constitution" className={styles.constitution}>
        <h1
          data-aos-duration="750"
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-delay="0"
          data-aos-offset="100"
        >
          <b>JOAFFL CONSTITUTION</b>
        </h1>
        <ul
          className={styles.ulHolder}
          data-aos-duration="750"
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-delay="0"
          data-aos-offset="100"
          data-aos-mirror="true"
        >
          <ul>
            <h3>Article 1: League Name and Purpose</h3>
            <h5>
              1.1 The league shall be known as THE JOAFFL (Jags of Aurora
              Fantasy Football League), established in 2014.
            </h5>
            <h5>
              1.2 The purpose of the league is to provide a fair and enjoyable
              fantasy football experience for all participants.
            </h5>
          </ul>
          <ul>
            <h3>Article 2: League Format</h3>
            <h5>
              2.1 The league will be played on the ESPN Fantasy Football
              platform.
            </h5>
            <h5>2.2 The scoring system will be PPR (Points Per Reception).</h5>
            <h5>
              2.3 The starting lineup consists of 1 quarterback (QB), 2 running
              backs (RB), 2 wide receivers (WR), 1 tight end (TE), 1 flex
              position (FLEX), 1 offensive Player (OP) that can be any position,
              1 defense/special teams (D/ST), and 1 kicker (K).
            </h5>
            <h5>
              2.4 The bench consists of 8 slots which are meant for starting
              lineup substituions. (6 regular and 2 IR)
            </h5>
            <h5>
              2.5 The (2) IR slots are intended for players that are on IR. As of
              06/13/2023 ESPN's platform has a glitch that allows you to add and
              IR player to the IR bench slot and will not require you to remove
              them once they are off of IR. This will not be tolerated in the
              league and team managers will be given 24 hours after the player
              is removed from IR to remove them from the IR slot.
            </h5>
            <h6>
              2.5.1 Punishment for not adhearing to the above regulation will
              result in a 25 point subtraction from that players score for the
              week.
            </h6>
            <h5>
              2.6 Team Limitations: QB 4(max), RB 8(max), WR 8(max), TE 3(max),
              D/ST 3(max) and K 3(max).
            </h5>
            <h5>
              2.7 The regular season will consist of 14 weeks, followed by
              playoffs which will consist of 3 weeks.
            </h5>
          </ul>
          <ul>
            <h3>Article 3: League Membership</h3>
            <h5>3.1 The league shall consist of 12 teams.</h5>
            <h6>
              3.1.1 If a player quits or is relegated from the league a CPU team
              will take his/her place.
            </h6>
            <h5>
              3.2 New members may join the league upon approval by the league
              Board members.
            </h5>
            <h5>
              3.3 Returning members will have priority over new members for
              league spots.
            </h5>
            <h5>
              3.4 Members are expected to participate actively and in good faith
              throughout the season.
            </h5>
            <h6>
              3.4.1 Punishment for EACH act of lack of engagement at any point
              in the league if determined by the board as negligent will result
              in a $25 fee (Max season fee for lack of engagement is $1000).
              This fee will be added to the league purse.
            </h6>
          </ul>
          <ul>
            <h3>Article 4: Draft and Team Rosters</h3>
            <h5>
              4.1 The draft order will be determined by a randomized draft order
              selection process.
            </h5>
            <h5>4.2 The draft will be conducted using a snake format.</h5>
            <h5>
              4.3 The draft date and time will be determined by a consensus
              among the league members.
            </h5>
            <h5>
              4.4 Each team's roster will consist of a maximum of 20 players,
              including both active and bench spots.
            </h5>
            <h5>
              4.5 All rosters must be compliant with the ESPN Fantasy Football
              platform's lineup requirements and limitations.
            </h5>
          </ul>
          <ul>
            <h3>Article 5: Player Transactions</h3>
            <h5>
              5.1 Each team will be allocated a budget of $250 at the start of
              the season.
            </h5>
            <h5>
              5.2 The budget can be used for player acquisitions, including
              waivers, free agency, and trades.
            </h5>
            <h5>
              5.3 The cost of acquiring players will be deducted from the team's
              budget.
            </h5>
            <h5>
              5.4 The league commissioner will maintain and monitor the budget
              for each team throughout the season.
            </h5>
            <h5>
              5.5 Any unused budget at the end of the season will not carry over
              to the following season.
            </h5>
            <h5>
              5.6 Trades involving budget considerations must not result in any
              team exceeding the allocated budget limit.
            </h5>
            <h5>
              5.7 The budget system aims to promote strategic player management
              and prevent excessive dominance by a few teams.
            </h5>
          </ul>
          <ul>
            <h3>Article 6: Scoring and Standings</h3>
            <h5>
              6.1 The league scoring system will be based on the ESPN Fantasy
              Football platform's default settings, with modifications for PPR
              (Please refer to ESPN's platform for more details).
            </h5>
            <h5>
              6.2 Standings will be determined by the total points scored
              throughout the regular season.
            </h5>
            <h5>
              6.3 Tiebreakers for standings and playoff seeding will be
              determined in the following order: head-to-head record, total
              points scored, and a coin toss if necessary.
            </h5>
          </ul>
          <ul>
            <h3>Article 7: Playoffs and Championship</h3>
            <h5>
              7.1 The top 7 teams from the regular season will advance to the
              playoffs.
            </h5>
            <h6>7.1.2 Number one seed will be awarded a bye week for the first week.</h6>
            <h5>
              7.2 The playoff schedule and format will be determined by the
              league commissioner.
            </h5>
            <h5>
              7.3 The team with the highest score in each playoff matchup will
              advance to the next round.
            </h5>
            <h5>7.4 The league championship will be held in [Week/Date].</h5>
            <h5>
              7.5 The league champion will be determined by the highest score in
              the championship matchup.
            </h5>
          </ul>
          <ul>
            <h3>Article 8: Disputes and Rule Changes</h3>
            <h5>
              8.1 Any disputes or conflicts should be brought to the attention
              of the leagues Board members.
            </h5>
            <h5>
              8.2 The league commissioner will have the final decision on all
              disputes, with fairness and integrity in mind.
            </h5>
            <h5>
              8.3 Rule changes can be proposed by any league member during the
              offseason and will be voted upon by the majority.
            </h5>
            <h5>
              8.4 Amendments to the constitution require a two-thirds majority
              vote by league Board members for approval.
            </h5>
          </ul>
          <ul>
            <h3>Article 9: Understanding and Agreement to the Constitution</h3>
            <h5>
              9.1 Each league member must thoroughly review and understand the
              contents of this constitution.
            </h5>
            <h5>
              9.2 Prior to participating in the league, every member is required
              to sign and submit a copy of the constitution, indicating their
              commitment to obey and follow the rules outlined herein.
            </h5>
            <h5>
              9.3 By signing the constitution, each member acknowledges their
              understanding of the league's guidelines and their agreement to
              adhere to them.
            </h5>
            <h5>
              9.4 Failure to comply with the constitution may result in
              disciplinary action, including but not limited to warnings, fines,
              suspension, or expulsion from the league, as determined by the
              league commissioner and/or the Board of Members.
            </h5>
            <h5>
              9.5 Amendments or revisions to the constitution will be
              communicated to all league members, and their acceptance of the
              updated constitution will be required within a specified
              timeframe.
            </h5>
            <h5>
              9.6 It is the responsibility of each league member to stay updated
              with any changes or modifications made to the constitution.
            </h5>
          </ul>
          <ul>
            <h3>Article 10: League Governance</h3>
            <h5>
              10.1 The league shall establish a Board of Members consisting of
              three individuals elected by the league members.
            </h5>
            <h5>
              10.2 The Board members will collaborate and come to a decision
              regarding any rule or regulation not explicitly outlined in this
              constitution.
            </h5>
            <h5>
              10.3 The Board members will serve as mediators in disputes and
              conflicts, working towards a fair and impartial resolution.
            </h5>
            <h5>
              10.4 The decisions made by the Board members will be final and
              binding, with the best interests of the league in mind.
            </h5>
            <h5>
              10.5 In the event of a Board member vacancy, a special election
              will be held to fill the position, following the guidelines
              established by the league commissioner.
            </h5>
            <h5>
              10.6 The Board members shall have the authority to propose
              amendments or revisions to the constitution, subject to approval
              by the league members through a majority vote.
            </h5>
            <h5>
              10.7 The league commissioner may be subject to impeachment at any
              point in time. 2/3 Board members are needed for the final
              decision.
            </h5>
            <h6>
              10.7.1 One of the Board members will be elected as the
              commissioner post impeachment.
            </h6>
          </ul>
        </ul>
      </div>
    </MainCard>
  );
}
export default Constitution;
