import React, { useState } from "react";
import styles from "./Losers.module.css";
import MainCard from "../../containers/MainCard";

import ReactPlayer from "react-player";
import { Link } from "react-scroll";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

function Losers() {
  const navigate = useNavigate();
  return (
    <>
      <Link
        onClick={() => navigate("/")}
        to="/"
        spy={true}
        smooth={true}
        offset={-60}
        duration={600}
      >
        <motion.div
          className={styles.return}
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 0.9 }}
          transition={{ type: "spring", stiffness: 400, damping: 17 }}
        >
          return home
        </motion.div>
      </Link>
      <MainCard>
        <section className={styles.losers}>
          <h1>2023-2024 JOAFFL Losers Bracket</h1>
          <div className={styles.loserListHolder}>
            <ul className={styles.loserList}>
              <div className={styles.loserListPair}>
                <li>Alex Hickey: 0.00pts</li>
                <li>Bye</li>
              </div>
              <div className={styles.loserListPair}>
                <li>Drew Daff: 0.00pts</li>
                <li>Scott Adsit: 0.00pts</li>
              </div>
              <div className={styles.loserListPair}>
                <li>Griff Petersen: 0.00pts</li>
                <li>Sam Nowak: 0.00pts</li>
              </div>
            </ul>

            <ul className={styles.loserList}>
              <div className={styles.loserListPair}>
                <li>Alex Hickey: 0.00pts</li>
                <li>TBD: 0.00pts</li>
              </div>
              <div className={styles.loserListPair}>
                <li>TBD: 0.00pts</li>
                <li>TBD: 0.00pts</li>
              </div>
            </ul>

            <ul className={styles.loserList}>
              <div className={styles.loserListPair}>
                <li>TBD: 0.00pts</li>
                <li>TBD: 0.00pts</li>
              </div>
            </ul>

            <ul className={styles.loserList}>
              <div className={styles.loserListPairChamp}> 
                {/* <li>TBD</li> */}
                <li>"Winner"</li>
              </div>
            </ul>

            {/* <ul className={styles.loserList}>
            <div className={styles.loserListPair}>
              <li>Alex Hickey</li>
              <li>Bye</li>
            </div>
            <div className={styles.loserListPair}>
              <li>Drew Daff</li>
              <li>Scott Adsit</li>
            </div>
            <div className={styles.loserListPair}>
              <li>Griff Petersen</li>
              <li>Sam Nowak</li>
            </div>
          </ul> */}
          </div>
        </section>
      </MainCard>
    </>
  );
}
export default Losers;
