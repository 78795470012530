import React, { useState, useEffect } from "react";

function GiphyShuffle() {
  const [randomNumber, setRandomNumber] = useState(null);

  useEffect(() => {
    const generateRandomNumber = () => {
      const random = Math.floor(Math.random() * 25) + 1;
      setRandomNumber(random);
    };
    generateRandomNumber();
  }, []);

  return (
    <>
      {randomNumber === 1 && (
        <>
          <div
            style={{
              width: "100vw",
              height: "100vh",
              // paddingBottom: "78%",
              // position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <iframe
              src="https://giphy.com/embed/sEms56zTGDx96"
              width="100%"
              height="100%"
              style={{ position: "absolute" }}
              frameBorder="0"
              class="giphy-embed"
              allowFullScreen
            ></iframe>
          </div>
          <p>
            <a href="https://giphy.com/gifs/woah-freaking-out-sEms56zTGDx96">
              via GIPHY
            </a>
          </p>
        </>
      )}
      {randomNumber === 2 && (
        <>
          <div
            style={{
              width: "100vw",
              height: "100vh",
              // paddingBottom: "78%",
              // position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <iframe
              src="https://giphy.com/embed/NDFMPiDXBahihJxXpw"
              width="100%"
              height="100%"
              style={{ position: "absolute" }}
              frameBorder="0"
              class="giphy-embed"
              allowFullScreen
            ></iframe>
          </div>
          <p>
            <a href="https://giphy.com/gifs/flex-ultraswole-hassan-mostafa-NDFMPiDXBahihJxXpw">
              via GIPHY
            </a>
          </p>
        </>
      )}
      {randomNumber === 3 && (
        <>
          <div
            style={{
              width: "100vw",
              height: "100vh",
              // paddingBottom: "78%",
              // position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <iframe
              src="https://giphy.com/embed/4j9XOYo6IVDK8"
              width="100%"
              height="100%"
              style={{ position: "absolute" }}
              frameBorder="0"
              class="giphy-embed"
              allowFullScreen
            ></iframe>
          </div>
          <p>
            <a href="https://giphy.com/gifs/dance-hot-fat-guy-4j9XOYo6IVDK8">
              via GIPHY
            </a>
          </p>
        </>
      )}
      {randomNumber === 4 && (
        <>
          <div
            style={{
              width: "100vw",
              height: "100vh",
              // paddingBottom: "78%",
              // position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <iframe
              src="https://giphy.com/embed/X4Tr6YIaWllZK"
              width="100%"
              height="100%"
              style={{ position: "absolute" }}
              frameBorder="0"
              class="giphy-embed"
              allowFullScreen
            ></iframe>
          </div>
          <p>
            <a href="https://giphy.com/gifs/X4Tr6YIaWllZK">via GIPHY</a>
          </p>
        </>
      )}
      {randomNumber === 5 && (
        <>
          <div
            style={{
              width: "100vw",
              height: "100vh",
              // paddingBottom: "78%",
              // position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <iframe
              src="https://giphy.com/embed/nrXif9YExO9EI"
              width="100%"
              height="100%"
              style={{ position: "absolute" }}
              frameBorder="0"
              class="giphy-embed"
              allowFullScreen
            ></iframe>
          </div>
          <p>
            <a href="https://giphy.com/gifs/problems-nrXif9YExO9EI">
              via GIPHY
            </a>
          </p>
        </>
      )}
      {randomNumber === 6 && (
        <>
          <div
            style={{
              width: "100vw",
              height: "100vh",
              // paddingBottom: "78%",
              // position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <iframe
              src="https://giphy.com/embed/12WhkSmwGOGIUM"
              width="100%"
              height="100%"
              style={{ position: "absolute" }}
              frameBorder="0"
              class="giphy-embed"
              allowFullScreen
            ></iframe>
          </div>
          <p>
            <a href="https://giphy.com/gifs/reaction-dance-reactiongifs-12WhkSmwGOGIUM">
              via GIPHY
            </a>
          </p>
        </>
      )}
      {randomNumber === 7 && (
        <>
          <div
            style={{
              width: "100vw",
              height: "100vh",
              // paddingBottom: "78%",
              // position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <iframe
              src="https://giphy.com/embed/Lopx9eUi34rbq"
              width="100%"
              height="100%"
              style={{ position: "absolute" }}
              frameBorder="0"
              class="giphy-embed"
              allowFullScreen
            ></iframe>
          </div>
          <p>
            <a href="https://giphy.com/gifs/token-Lopx9eUi34rbq">via GIPHY</a>
          </p>
        </>
      )}
      {randomNumber === 8 && (
        <>
          <div
            style={{
              width: "100vw",
              height: "100vh",
              // paddingBottom: "78%",
              // position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <iframe
              src="https://giphy.com/embed/Um3ljJl8jrnHy"
              width="100%"
              height="100%"
              style={{ position: "absolute" }}
              frameBorder="0"
              class="giphy-embed"
              allowFullScreen
            ></iframe>
          </div>
          <p>
            <a href="https://giphy.com/gifs/earth-mindblown-kepler-Um3ljJl8jrnHy">
              via GIPHY
            </a>
          </p>
        </>
      )}
      {randomNumber === 9 && (
        <>
          <div
            style={{
              width: "100vw",
              height: "100vh",
              // paddingBottom: "78%",
              // position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <iframe
              src="https://giphy.com/embed/S3Ot3hZ5bcy8o"
              width="100%"
              height="100%"
              style={{ position: "absolute" }}
              frameBorder="0"
              class="giphy-embed"
              allowFullScreen
            ></iframe>
          </div>
          <p>
            <a href="https://giphy.com/gifs/jack-nicholson-nodding-anger-management-S3Ot3hZ5bcy8o">
              via GIPHY
            </a>
          </p>
        </>
      )}
      {randomNumber === 10 && (
        <>
          <div
            style={{
              width: "100vw",
              height: "100vh",
              // paddingBottom: "78%",
              // position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <iframe
              src="https://giphy.com/embed/d3mlE7uhX8KFgEmY"
              width="100%"
              height="100%"
              style={{ position: "absolute" }}
              frameBorder="0"
              class="giphy-embed"
              allowFullScreen
            ></iframe>
          </div>
          <p>
            <a href="https://giphy.com/gifs/culture--think-hmm-d3mlE7uhX8KFgEmY">
              via GIPHY
            </a>
          </p>
        </>
      )}
      {randomNumber === 11 && (
        <>
          <div
            style={{
              width: "100vw",
              height: "100vh",
              // paddingBottom: "78%",
              // position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <iframe
              src="https://giphy.com/embed/l4Jz3a8jO92crUlWM"
              width="100%"
              height="100%"
              style={{ position: "absolute" }}
              frameBorder="0"
              class="giphy-embed"
              allowFullScreen
            ></iframe>
          </div>
          <p>
            <a href="https://giphy.com/gifs/meme-steak-seasoning-l4Jz3a8jO92crUlWM">
              via GIPHY
            </a>
          </p>
        </>
      )}
      {randomNumber === 12 && (
        <>
          <div
            style={{
              width: "100vw",
              height: "100vh",
              // paddingBottom: "78%",
              // position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <iframe
              src="https://giphy.com/embed/2LxosfDt7NIbu"
              width="100%"
              height="100%"
              style={{ position: "absolute" }}
              frameBorder="0"
              class="giphy-embed"
              allowFullScreen
            ></iframe>
          </div>
          <p>
            <a href="https://giphy.com/gifs/funny-memes-2LxosfDt7NIbu">
              via GIPHY
            </a>
          </p>
        </>
      )}
      {randomNumber === 13 && (
        <>
          <div
            style={{
              width: "100vw",
              height: "100vh",
              // paddingBottom: "78%",
              // position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <iframe
              src="https://giphy.com/embed/QE8hREXIgRXeo"
              width="100%"
              height="100%"
              style={{ position: "absolute" }}
              frameBorder="0"
              class="giphy-embed"
              allowFullScreen
            ></iframe>
          </div>
          <p>
            <a href="https://giphy.com/gifs/shocked-confused-sad-QE8hREXIgRXeo">
              via GIPHY
            </a>
          </p>
        </>
      )}
      {randomNumber === 14 && (
        <>
          <div
            style={{
              width: "100vw",
              height: "100vh",
              // paddingBottom: "78%",
              // position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <iframe
              src="https://giphy.com/embed/TqdbhSWUmWLC0"
              width="100%"
              height="100%"
              style={{ position: "absolute" }}
              frameBorder="0"
              class="giphy-embed"
              allowFullScreen
            ></iframe>
          </div>
          <p>
            <a href="https://giphy.com/gifs/TqdbhSWUmWLC0">via GIPHY</a>
          </p>
        </>
      )}
      {randomNumber === 15 && (
        <>
          <div
            style={{
              width: "100vw",
              height: "100vh",
              // paddingBottom: "78%",
              // position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <iframe
              src="https://giphy.com/embed/ESjyqZhDEpCo0"
              width="100%"
              height="100%"
              style={{ position: "absolute" }}
              frameBorder="0"
              class="giphy-embed"
              allowFullScreen
            ></iframe>
          </div>
          <p>
            <a href="https://giphy.com/gifs/deez-nuts-his-watermark-because-it-was-idea-my-brother-made-me-make-ESjyqZhDEpCo0">
              via GIPHY
            </a>
          </p>
        </>
      )}
      {randomNumber === 16 && (
        <>
          <div
            style={{
              width: "100vw",
              height: "100vh",
              // paddingBottom: "78%",
              // position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <iframe
              src="https://giphy.com/embed/11ISwbgCxEzMyY"
              width="100%"
              height="100%"
              style={{ position: "absolute" }}
              frameBorder="0"
              class="giphy-embed"
              allowFullScreen
            ></iframe>
          </div>
          <p>
            <a href="https://giphy.com/gifs/reaction-mrw-11ISwbgCxEzMyY">
              via GIPHY
            </a>
          </p>
        </>
      )}
      {randomNumber === 17 && (
        <>
          <div
            style={{
              width: "100vw",
              height: "100vh",
              // paddingBottom: "78%",
              // position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <iframe
              src="https://giphy.com/embed/cF7QqO5DYdft6"
              width="100%"
              height="100%"
              style={{ position: "absolute" }}
              frameBorder="0"
              class="giphy-embed"
              allowFullScreen
            ></iframe>
          </div>
          <p>
            <a href="https://giphy.com/gifs/face-need-put-cF7QqO5DYdft6">
              via GIPHY
            </a>
          </p>
        </>
      )}
      {randomNumber === 18 && (
        <>
          <div
            style={{
              width: "100vw",
              height: "100vh",
              // paddingBottom: "78%",
              // position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <iframe
              src="https://giphy.com/embed/xjZtu4qi1biIo"
              width="100%"
              height="100%"
              style="position:absolute"
              frameBorder="0"
              class="giphy-embed"
              allowFullScreen
            ></iframe>
          </div>
          <p>
            <a href="https://giphy.com/gifs/thumbs-up-xjZtu4qi1biIo">
              via GIPHY
            </a>
          </p>
        </>
      )}
      {randomNumber === 19 && (
        <>
          <div
            style={{
              width: "100vw",
              height: "100vh",
              // paddingBottom: "78%",
              // position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <iframe
              src="https://giphy.com/embed/3tBXMMMDQhvpEwRQrf"
              width="100%"
              height="100%"
              style={{ position: "absolute" }}
              frameBorder="0"
              class="giphy-embed"
              allowFullScreen
            ></iframe>
          </div>
          <p>
            <a href="https://giphy.com/gifs/nfl-sports-football-sport-3tBXMMMDQhvpEwRQrf">
              via GIPHY
            </a>
          </p>
        </>
      )}
      {randomNumber === 20 && (
        <>
          <div
            style={{
              width: "100vw",
              height: "100vh",
              // paddingBottom: "78%",
              // position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <iframe
              src="https://giphy.com/embed/Rd8xjzstWIkMzEWJZj"
              width="100%"
              height="100%"
              style={{ position: "absolute" }}
              frameBorder="0"
              class="giphy-embed"
              allowFullScreen
            ></iframe>
          </div>
          <p>
            <a href="https://giphy.com/gifs/nfl-baker-mayfield-Rd8xjzstWIkMzEWJZj">
              via GIPHY
            </a>
          </p>
        </>
      )}
      {randomNumber === 21 && (
        <>
          <div
            style={{
              width: "100vw",
              height: "100vh",
              // paddingBottom: "78%",
              // position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <iframe
              src="https://giphy.com/embed/M8gheIhsLcttS"
              width="100%"
              height="100%"
              style={{ position: "absolute" }}
              frameBorder="0"
              class="giphy-embed"
              allowFullScreen
            ></iframe>
          </div>
          <p>
            <a href="https://giphy.com/gifs/kate-upton-burger-M8gheIhsLcttS">
              via GIPHY
            </a>
          </p>
        </>
      )}
      {randomNumber === 22 && (
        <>
          <div
            style={{
              width: "100vw",
              height: "100vh",
              // paddingBottom: "78%",
              // position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <iframe
              src="https://giphy.com/embed/10briMdj6tGzHa"
              width="100%"
              height="100%"
              style={{ position: "absolute" }}
              frameBorder="0"
              class="giphy-embed"
              allowFullScreen
            ></iframe>
          </div>
          <p>
            <a href="https://giphy.com/gifs/spongebob-squarepants-mrs-puff-youre-fired-10briMdj6tGzHa">
              via GIPHY
            </a>
          </p>
        </>
      )}
      {randomNumber === 23 && (
        <>
          <div
            style={{
              width: "100vw",
              height: "100vh",
              // paddingBottom: "78%",
              // position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <iframe
              src="https://giphy.com/embed/50i6YRZxEiqkM"
              width="100%"
              height="100%"
              style={{ position: "absolute" }}
              frameBorder="0"
              class="giphy-embed"
              allowFullScreen
            ></iframe>
          </div>
          <p>
            <a href="https://giphy.com/gifs/funny-50i6YRZxEiqkM">via GIPHY</a>
          </p>
        </>
      )}
      {randomNumber === 24 && (
        <>
          <div
            style={{
              width: "100vw",
              height: "100vh",
              // paddingBottom: "78%",
              // position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <iframe
              src="https://giphy.com/embed/13746CZnj9zQwo"
              width="100%"
              height="100%"
              style={{ position: "absolute" }}
              frameBorder="0"
              class="giphy-embed"
              allowFullScreen
            ></iframe>
          </div>
          <p>
            <a href="https://giphy.com/gifs/snl-katy-perry-13746CZnj9zQwo">
              via GIPHY
            </a>
          </p>
        </>
      )}
      {randomNumber === 25 && (
        <>
          <div
            style={{
              width: "100vw",
              height: "100vh",
              // paddingBottom: "78%",
              // position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <iframe
              src="https://giphy.com/embed/WUeeGwT8d8KFa"
              width="100%"
              height="100%"
              style={{ position: "absolute" }}
              frameBorder="0"
              class="giphy-embed"
              allowFullScreen
            ></iframe>
          </div>
          <p>
            <a href="https://giphy.com/gifs/smash-WUeeGwT8d8KFa">via GIPHY</a>
          </p>
        </>
      )}
    </>
  );
}
export default GiphyShuffle;
