import React, { useState } from "react";
import styles from "./Lottery.module.css";
import MainCard from "../../containers/MainCard";

import ReactPlayer from "react-player";
import { Link } from "react-scroll";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

function Lottery() {
  const navigate = useNavigate();
  return (
    <>
      <Link
        onClick={() => navigate("/")}
        to="/"
        spy={true}
        smooth={true}
        offset={-60}
        duration={600}
      >
        <motion.div
          className={styles.return}
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 0.9 }}
          transition={{ type: "spring", stiffness: 400, damping: 17 }}
        >
          return home
        </motion.div>
      </Link>
      <MainCard>
        <section className={styles.lottery}>
          <h1>2023-2024 JOAFFL Draft Order</h1>
          <ul className={styles.lotteryList}>
            <li>1: Alex Hickey</li>
            <li>2: John Updyke III</li> 
            <li>3: Adam Dolata</li>
            <li>4: Cameron Schnell</li>
            <li>5: Austin Guthlein</li>
            <li>6: Griff Petersen</li>
            <li>7: Mikhail Shatokhin</li>
            <li>8: Drew Daff</li>
            <li>9: Alex Avalon</li>
            <li>10: Sam Nowak</li>
            <li>11: Scott Adsit</li>
            <li>12: Barbie Miller*</li>
          </ul>

          <h2>Hat Video</h2> 
          <ReactPlayer
            url="../../1.mp4" // Replace with the actual path to your video file
            controls // Show video controls (play, pause, volume, etc.)
            width="80%"
            height="80%"
          />
          <h2>Winnie Video</h2>
          <ReactPlayer
            url="../../3v2.mp4" // Replace with the actual path to your video file
            controls // Show video controls (play, pause, volume, etc.)
            width="80%"
            height="80%"
          />
        </section>
      </MainCard>
    </>
  );
}
export default Lottery;
