//External
import React from "react";
import { motion } from "framer-motion";
//Internal
//CSS
import styles from "./MainCard.module.css";

function MainCard({ children }) {
  return (
    <main className={styles.main}>
      <motion.section
        className={styles.section}
        key="mainCard"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.25 }}
      >
        {children}
      </motion.section>
    </main>
  );
}

export default MainCard;
